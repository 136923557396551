import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aller Anfang ist schwer: Wie dir der Einstieg in die Prüfung mit Screenreadern gelingt
und welche Tastaturbefehle und Gesten dir das Leben leichter machen.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.333333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB5ckpBRxxT//EABsQAQACAgMAAAAAAAAAAAAAAAEDEQACISIx/9oACAEBAAEFAtKpLxeT1O0oEn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAABAgcAAAAAAAAAAAAAAAABABECAxAhUWFx/9oACAEBAAY/ArhzisPVMGkWX//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFhMVH/2gAIAQEAAT8ht6HpiC/agiy4kQ+IGDKMNxRc/9oADAMBAAIAAwAAABAY3//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxANn//EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAgEBPxAiTQ3/xAAbEAACAwEBAQAAAAAAAAAAAAABEQAhMVFhof/aAAgBAQABPxAzyroRQwL7QyJlgV8vkADodFRJAOy9mHDVFWzfyLOBoOf/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Einstellungs-Menü am iPhone für den Screenreader VoiceOver",
          "title": "Einstellungs-Menü am iPhone für den Screenreader VoiceOver",
          "src": "/static/d008de0849a9b080db26f42cdaf6150c/e5166/iphone-voiceover-settings.jpg",
          "srcSet": ["/static/d008de0849a9b080db26f42cdaf6150c/f93b5/iphone-voiceover-settings.jpg 300w", "/static/d008de0849a9b080db26f42cdaf6150c/b4294/iphone-voiceover-settings.jpg 600w", "/static/d008de0849a9b080db26f42cdaf6150c/e5166/iphone-voiceover-settings.jpg 1200w", "/static/d008de0849a9b080db26f42cdaf6150c/b17f8/iphone-voiceover-settings.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Alexander Lehner`}</em></p>
    <h2>{`Mein erstes Mal`}</h2>
    <p>{`Als ich vor drei Jahren das erste Mal einen Screenreader am Laptop aktivierte, wurde ich kurz panisch.
Eine roboterhafte Stimme begann in hohem Tempo auf mich einzureden. Intuitiv versuchte ich das Gehörte
mit dem, was ich am Bildschirm sah, abzugleichen – und scheiterte kläglich. Stress pur!`}</p>
    <p>{`Gerade für sehende Menschen kann die Nutzung eines Screenreaders zu Beginn verwirrend sein. Etwa für
Web-Entwickler:innen, die ein frisch implementiertes Feature auf Barrierefreiheit prüfen möchten.
Oder für Tester:innen, die eine Applikation aus der Perspekte von Blinden und stark Sehbeeinträchtigten
testen. Daher möchte ich ein paar Tipps teilen, wie der Einstieg leichter fallen kann.`}</p>
    <h2>{`Tipps für den Einstieg`}</h2>
    <p>{`Für Desktop-Geräte unter Windows kann ich den kostenfreien Screenreader `}<a parentName="p" {...{
        "href": "https://www.nvaccess.org/"
      }}>{`NVDA`}</a>{` empfehlen.
Auf Apple-Geräten wie z.B. einem iPhone ist der Screenreader `}<a parentName="p" {...{
        "href": "https://de.wikipedia.org/wiki/VoiceOver"
      }}>{`VoiceOver`}</a>{`
bereits vorinstalliert. Das Pendant auf Android-Geräten heißt `}<a parentName="p" {...{
        "href": "https://support.google.com/accessibility/android/answer/6283677?hl=de"
      }}>{`TalkBack`}</a>{`.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Wo ist die Stopp-Taste?`}</strong>{` Jeder Screenreader bietet eine Möglichkeit, das Vorlesen zu unterbrechen.
Zum Beispiel, bei VoiceOver einfach mit zwei Fingern auf eine beliebige Stelle am Bildschirm tippen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Nicht so schnell!`}</strong>{` Geübte Screenreader-Nutzer:innen lassen sich Text in doppelter oder noch höherer
Geschwindigkeit vorlesen. Gerade zu Beginn solltet ihr das Sprechtempo drosseln und euren individuellen
Bedürfnissen anpassen. Bei NVDA kann man z.B. in den Optionen unter „Sprachausgabe“ die Geschwindigkeit
mit einem Schieberegler einstellen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Passende Stimme wählen`}</strong>{`: Wählt in den Einstellungen des Screenreaders eine Stimme aus, die ihr als
möglichst angenehm empfindet. Meistens gibt es mehrere männliche und weibliche Stimmen zur Auswahl.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Gesten und Tastaturbefehle`}</strong>{`: Alle gängigen Screenreader bieten eine Vielzahl an Gesten und Tastaturbefehlen,
mit denen man konkrete Aktionen schneller ausführen kann. Wenn ihr etwa bei aktiviertem TalkBack mit drei Fingern
nach links oder rechts wischt, könnt ihr einen Navigationsmodus auswählen (z.B. Überschriften). Dann mit einem Finger
nach oben oder unten wischen, um zum vorherigen oder nächsten Element dieser Art zu springen. Damit kannst du u.a.
schnell überprüfen, ob Elemente semantisch korrekt implementiert wurden.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Zu Befehl!`}</strong>{` Findet heraus, wie ihr den Screenreader am schnellsten ein- und ausschaltet. NVDA lässt sich
standardmäßig mit der Tastenkombination STRG + ALT + N starten und mit EINFG + Q ausschalten. Am iPhone müsst ihr
in den Einstellungen unter „Bedienungshilfen“ explizit VoiceOver als Kurzbefehl festlegen. Dann könnt ihr durch
dreifaches Drücken der Home-Taste den Screenreader ein- und ausschalten.`}</p>
      </li>
    </ol>
    <p>{`Am wichtigsten ist, dass du dir ausreichend Zeit lässt. Immer daran denken: Übung macht den Meister.`}</p>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nvaccess.org/files/nvda/documentation/userGuide.html"
        }}>{`NVDA User Guide`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dequeuniversity.com/screenreaders/nvda-keyboard-shortcuts"
        }}>{`NVDA Tastaturbefehle`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.apple.com/de-at/guide/iphone/iph3e2e2281/ios"
        }}>{`VoiceOver-Gesten`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.google.com/accessibility/android/answer/6151827?hl=de"
        }}>{`TalkBack-Gesten`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      